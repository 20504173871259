import React, { useEffect, useContext, useState } from "react";
import {
  Table,
  Pagination,
  Icon,
  Button,
  Dialog,
  Datagrid,
  ThemeToggler,
  Spinner,
} from "@abb/abb-common-ux-react";
import "./alarm-notifications.css";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import {
  BASE_URL,
  BASE_URL_ALARM_NOTIFICATIONS,
  ERROR_LIST,
} from "../../strings";
import { convertUtcToLocalString } from "../../util";
import { getToken } from "../Authentication/get-token";

function AlarmNotificationsData() {
  const {
    SpinnerVal,
    setSpinner,
    startTS,
    endTS,
    totalNotiRecords,
    settotalNotiRecords,
  } = useContext(RootContext);

  const [alarmNotificationResponse, setalarmNotificationResponse] = useState(
    []
  );
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  const initialDatas = React.useMemo(() => []);

  const initialData = React.useMemo(
    () =>
      initialDatas.map((v, i) => ({
        fields: {
          bay: v.bay,
          timeStamp: v.timeStamp,
          description: v.description,
        },
      })),
    []
  );
  const SUBSTATION_ID = localStorage.getItem("selectedSubstationId");
  const initialColumns = React.useMemo(
    () => [
      { fieldKey: "bay", title: "RELAY ID" },
      { fieldKey: "timeStamp", title: "FAULT TIME" },
      { fieldKey: "description", title: "DESCRIPTION" },
    ],
    []
  );
  const [data, setData] = React.useState(initialData);
  const [columns, setColumns] = React.useState(initialColumns);
  const [sortOrder, setSortOrder] = React.useState({
    "field": "timeStamp",
    "desc": true
  });
  useEffect(() => {
    fetchAlarmNoti();
  }, [startTS]);

  // useEffect(() => { }, [localStorage.getItem("alarm-noti-count")]);

  const fetchAlarmNoti = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        BASE_URL +
        `alarm-notification-summary?startDateTime=${startTS}&endDateTime=${endTS}&substationId=${SUBSTATION_ID}`, getToken()
      );
      //  `startDate=2023-11-02T10:00&endDate=2023-11-02T11:00&substationId=site`)
      let alarmNotiResp = response?.data?.alarmNotifications;

      let formatedData = alarmNotiResp?.map((v, i) => ({
        fields: {
          bay: v?.relayId,
          timeStamp: convertUtcToLocalString(v?.triggeredOn.split(".")[0]),
          description: v?.description,
        },
      }));

      setData(formatedData);

      settotalNotiRecords(alarmNotiResp.length);
      setError(false);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    } finally {

    }
  };




  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          sizeClass="small"
        />
      ) : (
        <>
          {data?.length > 0 ? (
            <Datagrid
              className="table-style-alarm-noti"
              style={{ height: "590px" }}
              data={data}
              columns={data?.length > 0 ? columns : ""}
              rowHeight="small"
              enableSorting={true}
              sortOrder={sortOrder}
              onSort={(so) => setSortOrder(so)}
            />
          ) : (
            <p className="center-text-error">{ERROR_LIST.NO_NOTIFICATIONS}</p>
          )}
        </>
      )}
    </>
  );
}

export default AlarmNotificationsData;
