import React, { useEffect, useState, useContext } from "react";
import {
  DonutChart,
  Spinner,
  Link,
} from "@abb/abb-common-ux-react";
import "./alarm-summary.css";
import { BASE_URL, ERROR_LIST } from "../../strings";
import { getAlarmSummaryLegendsColor } from "../../util";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import { getToken } from "../Authentication/get-token";

function AlarmSummary() {
  const { setSpinner, startTS, endTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading
  const { alarmAPIData, setalarmAPIData } = useState([]);
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = React.useState([]);
  const SUBSTATION_ID = localStorage.getItem("selectedSubstationId");
  const [legendPosition, setLegendPosition] = React.useState("right");
  const [showLabel, setShowLabel] = React.useState(true);
  const [showValueText, setShowValueText] = React.useState(true);
  const [showDescription, setShowDescription] = React.useState(true);
  const [valueHiding, setValueHiding] = React.useState(true);
  const [enableHoverOnChart, setEnableHoverOnChart] = React.useState(true);
  const [enableHoverOnLegend, setEnableHoverOnLegend] = React.useState(true);
  const [customLegendTemplate, setCustomLegendTemplate] = React.useState(false);
  const [hiddenItems, setHiddenItems] = React.useState([]);
  const [hoverItem, setHoverItem] = React.useState();
  setSpinner(true);
  React.useEffect(() => {
    fetchAlarmSumData();
  }, [startTS]);
  let fetchAlarmSumData = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        BASE_URL +
        `alarm-notification-summary?startDateTime=${startTS}&endDateTime=${endTS}&substationId=${SUBSTATION_ID}`, getToken()
      );
      let jsonArray = response.data.alarmSummary.data;
      const updatedJsonArray = jsonArray.map((obj) => ({
        ...obj,
        label: obj?.key,
        color: getAlarmSummaryLegendsColor(obj.key),
      }));
      setData(updatedJsonArray);
      setispin(false);
      setError(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
          setispin(false);
        } else if (status >= 500 && status < 600) {
          setError(true);
          setispin(false);
        } else {
          setError(true);
          setispin(false);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };

  const formatLegendItem = (d) => (
    <span>
      <span style={{ fontSize: "80%" }}> {d?.value}</span>
      <span style={{ color: d.color }}>{d?.label}</span>
    </span>
  );

  const dominantValue = data
    .slice()
    .filter((i) => hiddenItems.indexOf(i.key) === -1)
    .sort((a, b) => (a.value < b.value ? 1 : -1))[0];
  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          sizeClass="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span
              style={{ margin: "auto" }}
              color="dark-grey"
              sizeClass="small"
            >
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  fetchAlarmSumData();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data?.length > 0 ? (
                <DonutChart
                  style={{
                    width: "100%",
                    padding: "20px 0px",
                    margin: "0px 20px",
                  }}
                  legendPosition={legendPosition}
                  allowValueHiding={valueHiding}
                  disableHoverOnChart={!enableHoverOnChart}
                  disableHoverOnLegend={!enableHoverOnLegend}
                  legendItemFormatter={
                    customLegendTemplate ? formatLegendItem : undefined
                  }
                  label={
                    !showLabel
                      ? undefined
                      : `${hoverItem ? hoverItem.label : dominantValue?.label}`
                  }
                  valueText={
                    !showValueText
                      ? undefined
                      : `${hoverItem ? hoverItem.value : dominantValue?.value}`
                  }
                  description={showDescription && `${hoverItem ? " " : ""}`}
                  hiddenItems={hiddenItems}
                  onHiddenItemsChanged={(items) => setHiddenItems(items)}
                  onHover={(item) =>
                    setHoverItem(data?.filter((i) => i.key === item)[0])
                  }
                  onBlur={() => setHoverItem(undefined)}
                  data={data}
                />
              ) : (
                <p className="center-text-error">
                  {ERROR_LIST.ALARM_SUMMARY_EMPTY_DATA}
                </p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default AlarmSummary;
