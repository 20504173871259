import React, { useContext, useState } from "react";
import { TabItem, TabControl } from "@abb/abb-common-ux-react";
import Card from "../../components/Card/card";
import SubstationFilters from "../substation-explorer/substation-filters";
import DashboardMetrics from "./metrics";
import DisturbanceEvents from "../../components/Bar-Disturbance-Events/disturbance-events";
import AlarmSummary from "../../components/Donut-Alarm-Summary/alarm-summary";
import DisturbanceMeasurementData from "../../components/Disturbance-Records/disurbance-measurements-datagrid";
import AlarmNotificationsData from "../../components/Alarm-Notifications/alarm-noti-datagrid";
import SLD from "../../components/SLD/sld";
import HistoricalReports from "../../components/Historical-Reports/historical-reports";
import { RootContext } from "../../contexts/root-context";
import chatbot from "../../assets/chat-bot.png";
import { CHATBOT_REDIRECT_LINK } from "../../strings";
import { WithPopup } from "@abb/abb-common-ux-react";
import { Popup } from "@abb/abb-common-ux-react";
import { Dialog } from "@abb/abb-common-ux-react";
function Dashboard() {
  const { relay, drmetrics } = useContext(RootContext);
  const [isDialogOpen, setisDialogOpen] = useState(false);

  const tripMetric = [
    { metricName: "# Trip Events", metricScore: relay?.trips, metricStat: relay?.tripsChange },
  ];

  const distRecordsMetric = [
    { metricName: "# Disturbance records", metricScore: drmetrics?.drs, metricStat: drmetrics?.drchange },
  ];
  const ackDRMetric = [
    { metricName: "# Acknowledged DR", metricScore: drmetrics?.ackDR, metricStat: drmetrics?.ackDRChange },
  ];

  const UnackDRMetric = [
    { metricName: "# Unacknowledged DR", metricScore: drmetrics?.unAckDR, metricStat: drmetrics?.unAckDRChange },
  ];

  const healthyRelayMetrics = [
    { metricName: "# Healthy Relays", metricScore: relay?.healthyRelays, metricStat: relay?.healthyRelaysChange },
  ];

  const unHealthyRelayMetrics = [
    { metricName: "# Unhealthy Relays", metricScore: relay?.unhealthyRelays, metricStat: relay?.unhealthyRelaysChange },
  ];
  const [activeTab, setActiveTab] = React.useState(0);

  /*Chatbot*/
  const clearData = () => {
    setisDialogOpen(false);
  }
  /*chatbot*/
  return (
    <>
      {isDialogOpen &&
        (<Dialog
          className="dialog-container-chatbot"
          showCloseButton={true}
          closeOnEscape={true}
          closeOnLostFocus={true}
          hideBackground={true}

          onClose={() => {
            clearData();
          }}
        >
          <p className="chatbot-head"><img className="chatbot-head-icon" src={chatbot} alt="Chatbot Icon" /><span className="chatbot-title"> CogniE</span></p>
          <iframe className="chat-window" src={CHATBOT_REDIRECT_LINK} ></iframe>

        </Dialog >)
      }
      <div className="row">
        <div className="dashboard-page-content" >
          {/* <UserSession /> */}
          <TabControl
            containerId="tab-container"
            activeTab={activeTab}
            onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
          >

            <TabItem title="DR Monitoring Dashboard">
              <div className="row">
                <SubstationFilters
                  substationTitle={"Substation DR Monitoring Dashboard"}
                />
              </div>
              <div className="row">

                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "8px" }} >
                  <DashboardMetrics position={3} legends={tripMetric} />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                  <DashboardMetrics position={3} legends={distRecordsMetric} />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                  <DashboardMetrics position={3} legends={UnackDRMetric} />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                  <DashboardMetrics position={3} legends={ackDRMetric} />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                  <DashboardMetrics position={3} legends={healthyRelayMetrics} />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                  <DashboardMetrics position={3} legends={unHealthyRelayMetrics} />
                </div>
              </div>
              <div className="row"  >
                <div className="col-5 widgets-container">
                  <SLD />
                </div>
                <div className="col-4 widgets-container">
                  <Card
                    widgetName="Disturbance Records"
                    widgetUpdateTime="2"
                    component={<DisturbanceMeasurementData />}
                  ></Card>
                  <Card
                    widgetName="Alarm Notifications"
                    widgetUpdateTime="2"
                    component={<AlarmNotificationsData />}
                  ></Card>
                </div>
                <div className="col-3 widgets-container">
                  <Card
                    widgetName="Disturbance Occurrence"
                    widgetUpdateTime="2"
                    component={<DisturbanceEvents />}
                  >
                  </Card>
                  <Card
                    widgetName="Alarm Summary"
                    widgetUpdateTime="2"
                    component={<AlarmSummary />}
                  ></Card>
                </div>
              </div>
            </TabItem>
            <TabItem title="Historical Reports">
              <HistoricalReports />
            </TabItem>

          </TabControl>


          <div>
            <div id="tab-container" />
          </div>


        </div>

        <div id="chatbot-icon">
          <WithPopup >
            <a onClick={() => { setisDialogOpen(true) }} id="chatbot-icon-link" target="_blank">
              <img src={chatbot} alt="Chatbot Icon" />
            </a>
            <Popup
              trigger={'hover'}
              closeOnLostFocus={true}
              position={'top right'}
              className="c-bot-tooltip" followCursor={false} triggerOnClick={false}>
              <div className="c-bot-tooltip" >
                Hello! I am CogniE,<br />
                How can I Cognify you today?
              </div>
            </Popup>
          </WithPopup>

        </div>

      </div>
    </>
  );
}
export default Dashboard;
