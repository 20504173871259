import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import "./forgot-password.css";
import { MESSAGES } from "../../strings";
import { Button, Dialog, Link } from "@abb/abb-common-ux-react";

const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [resetPasswordStep, setResetPasswordStep] = useState(1);
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [isValidnewpwd, setisValidnewpwd] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [code, setCode] = useState('');
    const [formValues, setFormValues] = useState({
        email: "",
        code: "",
        newpassword: "",
        confirmpassword: "",
    });
    function validateEmail(email) {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Test the email against the regex
        return emailRegex.test(email);
    }

    useEffect(() => {

    }, [formErrors, isValid, isValidnewpwd])

    const handleEmailChange = async (e) => {
        setFormErrors({})
        setUsername(e.target.value)
        setFormValues(prevState => ({ ...prevState, [e?.target?.id]: e.target.value }));
    }

    const handlePasswordChange = (e) => {
        let newPwd = e.target.value;
        setFormValues(prevState => ({ ...prevState, [e?.target?.id]: newPwd }));

        const isValidPassword = checkPwdRules(e.target.value)
        setIsValid(isValidPassword)
        if (!isValidPassword) {
            setFormErrors(prevState => ({ ...prevState, passowrd: "Password must contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter." }))
        } else {
            setFormErrors({})
        }
    };
    const handleConfirmPwd = (e) => {
        const newValue = e.target.value;
        setInputValue((prevValue) => {
            return newValue;
        });
    };
    const handleConfirmCode = (e) => {
        const newValue = e.target.value;
        setCode((prevValue) => {
            return newValue;
        });
    };

    const checkPwdRules = (newPassword) => {
        // Password validation regular expressions
        const hasNumber = /\d/.test(newPassword);
        const hasSpecialChar = /[!@#_$%^&*(),.?":{}|<>]/.test(newPassword);
        const hasUpperCase = /[A-Z]/.test(newPassword);
        const hasLowerCase = /[a-z]/.test(newPassword);
        // Check if all requirements are met
        // setIsValid(hasNumber && hasSpecialChar && hasUpperCase && hasLowerCase);
        return hasNumber && hasSpecialChar && hasUpperCase && hasLowerCase;
    }
    const sendVerificationCode = async () => {
        let getVerificationCodeErrors = {}
        if (!validateEmail(formValues.email)) {
            // errors.email = 'Enter valid email.';
            setFormErrors(prevState => ({ ...prevState, email: 'Enter valid email' }))
            getVerificationCodeErrors.email = "Enter valid email"
        }
        if (Object.keys(getVerificationCodeErrors).length === 0) {

            try {
                await Auth.forgotPassword(username);
                setResetPasswordStep(2);
                setFormValues(prevState => ({
                    ...prevState,
                    code: "",
                    newpassword: "",
                }));
                setFormErrors({})
            } catch (error) {
                if (error.message === 'User is disabled.') {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: 'Account is temporarily disabled. Please wait for admin approval.' }))
                } else if (error.code === 'UserNotFoundException') {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: 'User does not exist.' }))
                } else if(error.code === 'InvalidParameterException' && error.message === "Cannot reset password for the user as there is no registered/verified email or phone_number") {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: 'Account is not verified. Please wait for admin approval.' }))
                } else if (error.toString().includes("AuthError") == false) {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: error.message }))
                }
            }
        }
    };

    const cancelForgotPwd = () => {
        navigate("../");
    }

    const checkVerificationCode = async () => {
        // Clear any previous errors
        setFormErrors({});

        let error_code = "";

        console.log("Form errors: ", formErrors);

        const codeRegex = /^\d{6}$/;

        if (!code || code.trim() === '') {
            // Check if code is empty
            error_code = "Verification code cannot be empty";
        } else if (!codeRegex.test(code)) {
            // Check if code is exactly 6 digits
            error_code = "Verification code should be a six digit number only";
        }

        if (error_code !== "") {
            setFormErrors(prevState => ({ ...prevState, code: error_code}));
            console.log("Form errors: ", formErrors);
            return;
        }

        if (error_code === "") {
            try {
                // Cannot verify the confirmation code
                // await Auth.confirmSignUp(formValues.email, code);
                setCode(code);
                setResetPasswordStep(3);
            } catch (error) {
                setFormErrors(prevState => ({ ...prevState, unauthmessage: error.message }))
            }
        }

        console.log("Form errors: ", formErrors);
    };

    const resetPassword = async () => {

        checkPwdRules(formValues.password)

        if (formValues.password != inputValue) {
            setFormErrors(prevState => ({ ...prevState, confirmpassword: "Confirm password must be match with the password" }))
            setisValidnewpwd(true)
        } else {
            setisValidnewpwd(false)

            setFormErrors({})
        }
        if (Object.keys(formErrors).length === 0) {
            try {
                await Auth.forgotPasswordSubmit(formValues.email, code, inputValue);
                setResetPasswordStep(4);
                // navigate("../");
            } catch (error) {
                if(error.code === 'CodeMismatchException') {
                    setFormErrors(prevState => ({ ...prevState, code: "Invalid verification code provided, please try again." }))
                    console.log(formErrors)
                    setCode('')
                    setIsValid(false)
                    setResetPasswordStep(2);
                } else {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: error.message }))
                }
            }
        }
        };
    const resendVerificationCode = async () => {
        try {
            await Auth.resendSignUp(username);

        } catch (error) {
            console.error('Error resending verification code', error);
        }
    };

    return (
        <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light login-container">

            <div className="ABB_CommonUX_LoginScreen__header">
                <div />
                <div className="ABB_CommonUX_LoginScreen__logo" />
            </div>
            <div className="ABB_CommonUX_LoginScreen__contentWrapper">

                <div className="ABB_CommonUX_LoginScreen__content">

                    <h1 className="ABB_CommonUX_LoginScreen__productName">Forgot password?</h1>

                    <div className="ABB_CommonUX_LoginScreen__form">

                        {resetPasswordStep === 1 &&
                            <>
                                <div className="ABB_CommonUX_LoginScreen__formRow">
                                    <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 600 }}>
                                        If you have forgotten your password, please enter your e-mail address. You will receive Instructions on how to reset the password.
                                        <div />
                                    </div>
                                </div>
                                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ width: "400px", fontSize: "14px", fontWeight: 500 }}>
                                    <div className="ABB_CommonUX_LoginScreen__password">
                                        <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                                            <div className="ABB_CommonUX_Input__labelContainer">
                                                <span className="ABB_CommonUX_Input__label" style={{ fontSize: "14px", fontWeight: 500 }}>E-mail Address</span>
                                            </div>
                                            <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                                                <input
                                                    className="ABB_CommonUX_Input__inputElement"
                                                    type="email"
                                                    required
                                                    name="email"
                                                    id="email"
                                                    onChange={handleEmailChange}

                                                />
                                                <span className="ABB_CommonUX_Input__iconWrapper">
                                                    <i className="ABB_CommonUX_Icon__root">
                                                        <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_envelope_16" />
                                                    </i>
                                                </span>
                                            </div>
                                            {formErrors.email && <span className="error-display">{formErrors.email}</span>}


                                            <br />
                                            <br />
                                            <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="send-email" onClick={sendVerificationCode} >
                                                {/*  */}
                                                <div>
                                                    <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                        Send Email
                                                    </span>
                                                </div>
                                            </button>&nbsp;&nbsp;
                                            <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="forgot-email-cancel" onClick={cancelForgotPwd} >
                                                {/*  */}
                                                <div>
                                                    <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                        Cancel
                                                    </span>
                                                </div>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </>}
                        {formErrors.unauthmessage && <span className="error-display-pwd">{formErrors.unauthmessage}</span>}

                        {resetPasswordStep === 2 &&
                            <> <div className="ABB_CommonUX_LoginScreen__formRow">
                                <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 600 }}>
                                    A verification code has been sent to your email.
                                    Please enter the code below to continue.
                                    <div />
                                </div>
                            </div>
                                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ fontSize: "14px", fontWeight: 500 }}>
                                    <div className="ABB_CommonUX_LoginScreen__password">
                                        <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                                            <div className="ABB_CommonUX_Input__labelContainer">
                                                <span className="ABB_CommonUX_Input__label"><b>Verification Code</b></span>
                                            </div>
                                            <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                                                <input
                                                    className="ABB_CommonUX_Input__inputElement"
                                                    type="text"
                                                    required
                                                    name="verify-code"
                                                    id="verify-code"
                                                    onKeyUp={handleConfirmCode}

                                                />
                                            </div>
                                            {formErrors.code && <span className="error-display">{formErrors.code}</span>}
                                            <br />
                                            <br />
                                            <div className="ABB_CommonUX_LoginScreen__buttons ABB_CommonUX_LoginScreen__flexSpring">
                                                <div className="ABB_CommonUX_LoginScreen__button">
                                                    <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="send-email" onClick={checkVerificationCode} >
                                                        {/*  */}
                                                        <div>
                                                            <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                                Continue
                                                            </span>
                                                        </div>
                                                    </button>

                                                    <Button
                                                        sizeClass={"medium"}
                                                        text="Back"
                                                        type="ghost"
                                                        style={{ marginLeft: '32px' }}
                                                        onClick={() => setResetPasswordStep(1)}
                                                    />

                                                    <Link style={{marginLeft: '32px', cursor: 'pointer'}} onClick={resendVerificationCode} discreet={true}>Resend Code </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div></>}

                                {resetPasswordStep === 3 &&
                            <> <div className="ABB_CommonUX_LoginScreen__formRow">
                                <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 600 }}>
                                    Please enter new password.
                                    <div />
                                </div>
                            </div>
                                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ fontSize: "14px", fontWeight: 500 }}>
                                    <div className="ABB_CommonUX_LoginScreen__password">
                                        <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                                            {formErrors.code && <span className="error-display">{formErrors.code}</span>}
                                            <br />

                                            <div className="ABB_CommonUX_Input__labelContainer">
                                                <span className="ABB_CommonUX_Input__label"><b>New Password</b></span>
                                            </div>
                                            <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                                                <input
                                                    className="ABB_CommonUX_Input__inputElement"
                                                    type="password"
                                                    required
                                                    name="password"
                                                    onChange={handlePasswordChange}

                                                    id="password"
                                                />
                                            </div>
                                            {formErrors.passowrd || !isValid ? <span className="error-display">{formErrors.passowrd}</span> : <span className="valid-display-pwd">Password is valid</span>}
                                            <br />
                                            <div className="ABB_CommonUX_Input__labelContainer">
                                                <span className="ABB_CommonUX_Input__label"><b>Confirm New Password</b></span>
                                            </div>
                                            <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                                                <input
                                                    className="ABB_CommonUX_Input__inputElement"
                                                    type="password"
                                                    required
                                                    name="confirm-password"
                                                    id="confirm-password"
                                                    onKeyUp={handleConfirmPwd}

                                                />
                                            </div>

                                            {isValidnewpwd && <span className="error-display">{formErrors.confirmpassword}</span>}
                                            <br />
                                            <br />
                                            <div className="ABB_CommonUX_LoginScreen__buttons ABB_CommonUX_LoginScreen__flexSpring">
                                                <div className="ABB_CommonUX_LoginScreen__button">
                                                    <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="send-email" onClick={resetPassword} >
                                                        {/*  */}
                                                        <div>
                                                            <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                                Continue
                                                            </span>
                                                        </div>
                                                    </button>&nbsp;&nbsp; &nbsp;&nbsp;

                                                    <Button
                                                        sizeClass={"medium"}
                                                        text="Back"
                                                        type="ghost"
                                                        onClick={() => { setCode(''); setResetPasswordStep(2)}}
                                                    />&nbsp;&nbsp; &nbsp;&nbsp;

                                                    <Button
                                                        sizeClass={"medium"}
                                                        text="Cancel"
                                                        type="ghost"
                                                        onClick={cancelForgotPwd}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div></>}

                                {resetPasswordStep === 4 &&
                                    <div class="ReactModal__Overlay ReactModal__Overlay--after-open ABB_CommonUX_Dialog__overlay ABB_CommonUX_Dialog__halfOpacityBackdrop ABB_CommonUX_Dialog__light">
                                        <Dialog 
                                        className="signup-dialog-container"
                                        title={MESSAGES.FORGOT_PASSWORD_TITLE}
                                        >
                                        <p>{MESSAGES.FORGOT_PASSWORD_BODY}</p>
                            
                                        <Button
                                            sizeClass={"medium"}
                                            text="Back to Login"
                                            type="primary-blue"
                                            onClick={cancelForgotPwd}
                                        />
                                        </Dialog>
                                    </div>
                                }
                    </div>



                    {/* </form> */}
                </div>

            </div>


        </div>

    );
};

export default ForgotPassword;
