import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import ABBLogin from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import SubstationExplorer from "./pages/substation-explorer";
import Navbar from "./components/Navbar/navbar";
import ManageUsers from "./pages/user-management/manage-users";
import FullSld from "./components/SLD/full-sld";
import EditUser from "./pages/user-management/edit-user";
import Signup from "./pages/signup/signup";
import SignupSuccess from "./pages/signup/signup-success";
import SignUpPostVerification from "./pages/signup/signup-post-verification";
import ForgotPassword from "./pages/forgot-password/forgot-password";
function CogniENRoutes() {
  const currentLocation = useLocation();
  const nonNavBarLinks = ["/", "/change-password", "/signup", "/verify-mfa", "/signup-success", "/forgot-password", "/signup-verified", "/login"]
  const [keywordExists, setkeywordExists] = useState();
  useEffect(() => {
    // Handle the URL change here
    let isNonNavBarLink = nonNavBarLinks.indexOf(currentLocation.pathname) !== -1;

    setkeywordExists(isNonNavBarLink);
  }, [currentLocation.pathname]);

  return (

    <>
      {!keywordExists && <Navbar />
      }

      <Routes>
        <Route exact path="#/" element={<ABBLogin />} />
        <Route exact path="/" element={<ABBLogin />} />
        <Route exact path="/login" element={<ABBLogin />} />
        <Route path="/settings" element={<ManageUsers />} />
        {/* <Route path="/settings/manageusers" element={<ManageUsers />} /> */}
        <Route path="/substation-explorer" element={<SubstationExplorer />} />
        <Route path="/substation-dashboard/*" element={<Dashboard />} />
        <Route path="/sld-preview" element={<FullSld />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/edit-user/*" element={<EditUser />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/signup-success" element={<SignupSuccess />} />
        <Route exact path="/signup-verified" element={<SignUpPostVerification />} />
      </Routes>

    </>
  );
}

export default CogniENRoutes;
